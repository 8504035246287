body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
  	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    	monospace;
}

h3 {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 36px;
	line-height: 140%;
	color: #465967;
}

p,
.modal-step-copy {
	font-family: "Poppins", sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 160%;
	color: #465967;
}

.no-scroll {
	overflow-y: hidden;
}

.z-top {
	position: relative;
	z-index: 100001;
}

#snotes-main {
	background-color: #FFFFFF;
}

#snotes-top-section {
	position: relative;
	background-color: #FCFCFC;
}

#snotes-top-section.set-z > * {
	position: relative;
}

#snotes-top-section-background {
	background-image: url('../images/background.svg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position-y: 0;
	background-position-x: center;
	width: 100%;
	position: absolute !important;
	bottom: 0;
	left: 0;
	height: 778px;
	z-index: 1 !important;
}

#snotes-top-section-background.space-bottom {
	bottom: -6px;
}

#snotes-main.darkmode-bg {
	background-color: #121212;
}

#snotes-top-section.darkmode-bg {
	background-color: #222222;
}

#snotes-top-section-background.darkmode-bg {
	background-image: url('../images/background_dark.svg');
}

#main-textarea {
	min-height: 570px;
	height: 600px;
	width: 570px;
	max-width: 80%;
	background: #FCFCFC;
	border: 1px solid #F9F9F9;
	box-sizing: border-box;
	box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.07);
	border-radius: 8px 8px 0px 0px;
	font-family: "Poppins", sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 160%;
	padding: 15px;
	color: #465967;
	z-index: 2;
	position: relative !important;
	margin-left: auto;
	margin-right: auto;
}

#main-textarea.darkmode {
	background: #414141;
	color: #E0E0E0;
	border: 1px solid #121212;
}

#main-textarea.darkmode::placeholder {
	color: #A9A9A9;
}

.link {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 140%;
	text-decoration: underline;
	color: #2D9CDB;
	cursor: pointer;
}

.link:hover {
	text-decoration: none;
}

.btn-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 27px;
	max-width: 570px;
	margin-left: auto;
	margin-right: auto;
	z-index: 9999;
}

#skip-back,
#skip-fwd,
#restart {
	height: 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: auto;
	margin-bottom: auto;
}

.pointer, .pointer > * {
	cursor: pointer !important;
}

#skip-back {
	transform: rotate(180deg);
}

#current-word {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: normal;
	line-height: 140%;
	text-align: center;
	color: #465967;
}

#current-word.darkmode {
	color: #F2C94C;
}

.hiw-steps-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.hiw-step {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-bottom: 20px;
	margin-left: 4px;
	margin-right: 4px;
}

#hiw-copy {
	font-size: 18px;
}

.hiw-step-label-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.hiw-step-label {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 140%;
	color: #465967;
	margin-left: 8px;
}

#about {
	margin-top: 100px;
	width: 918px;
	max-width: 85%;
	margin-left: auto;
	margin-right: auto;
}

#hiw-header {
	margin-bottom: 20px;
}

.font-size-btn,
#tts-btn,
#wpm-select {
	background-color: #F2F2F2;
	color: #465967;
	border-radius: 8px;
	border: none;
	height: 42px;
	margin-top: auto;
	margin-bottom: auto;
	transition: ease-in all .2s;
}

#play-pause {
	transition: ease-in all .2s;
}

.font-size-btn:hover,
#tts-btn:hover,
#wpm-select:hover {
	background-color: #2D9CDB;
	color: #F2F2F2;
}

#skip-back:hover > svg > *,
#restart:hover > svg > *,
#skip-fwd:hover > svg > * {
	fill: #2D9CDB;
}

.font-size-btn.darkmode,
#tts-btn.darkmode,
#wpm-select.darkmode {
	background-color: #BDBDBD;
	color: #333333;
}

.font-size-btn.darkmode:hover,
#tts-btn.darkmode:hover,
#wpm-select.darkmode:hover,
.modal-next-btn:hover {
	background-color: #5AB1E3;
	color: #F2F2F2;
}

#skip-back.darkmode:hover > svg > *,
#restart.darkmode:hover > svg > *,
#skip-fwd.darkmode:hover > svg > *,
#play-pause:hover > svg > circle,
.social-link:hover > svg > path  {
	fill: #5AB1E3;
}

.font-size-btn:active,
#tts-btn:active,
#wpm-select:active,
.modal-next-btn:active {
	background-color: #1F80B7 !important;
}

#skip-back:active > svg > *,
#restart:active > svg > *,
#skip-fwd:active > svg > *,
#play-pause:active > svg > circle {
	fill: #1F80B7 !important;
}

#wpm-select {
	background: #F2F2F2 url('../images/select_bg.svg') no-repeat 0;
	background-position: 8px;
	padding-left: 28px;

	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	letter-spacing: 0.035em;
}

.font-size-btn,
#tts-btn {
	width: 42px;
	padding: 8px;
}

#font-buttons {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 20%;
}

.btn-close-x {
	cursor: pointer;
	height: 20px;
	width: 20px;
	margin-top: auto;
	margin-bottom: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

#tutorial-icon {
	cursor: pointer;
	margin-top: auto;
	margin-bottom: auto;
	padding-bottom: 2px;
}

#tutorial-icon:hover > path {
    fill: #2D9CDB !important; 
}

#tutorial-icon:hover > rect {
	stroke: #2D9CDB;
}

#footer-logo {
	margin-bottom: 20px;
	margin-top: 20px;
}

.footer-container {
	padding-bottom: 20px;
	margin-top: 40px;
}

#footer-social-container {
	width: 102px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 12px;
}

#nav-social-container {
	display: flex;
    justify-content: space-around;
    width: 120px;
}

.footer-container > * {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 200%;
	text-align: center;
	letter-spacing: 0.5px;
	color: #828282;
}

.heart-emoji {
	color: #EB5757;
}

.footer-link {
	cursor: pointer;
}

.footer-link:hover {
	text-decoration: underline;
}

.social-link {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.highlight {
	text-decoration: underline;
	text-decoration-color: #2D9CDB;
}

.highlight.dark-mode {
	text-decoration-color: #F2C94C;
}

#play-tutorial {
	z-index: 2;
	margin-bottom: 20px;
	margin-top: -20px;
	width: 120px;
	margin-left: auto;
	margin-right: auto;
}

@media screen and (max-width: 991px) {
	.hiw-steps-container {
		flex-direction: column;
	}
	.hiw-step {
		width: 300px;
		justify-content: flex-start;
		margin-left: auto;
		margin-right: auto;
	}
	#tutorial-icon {
		margin-right: 20px;
	}
}

@media screen and (max-width: 374px) {
	#tutorial-icon {
		margin-right: 4px;
	}
	#snotes-navbar-mobile {
		padding: 8px 2px;
	}
}

.darkmode-font {
	color: #E0E0E0 !important;
}

.flex-space-evenly {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}

.flex-col-center {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.flex-row-center {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

#mobile-darkmode-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	padding-top: 6px;
	padding-bottom: 6px;
}

.navbar-collapse.collapse.show,
.navbar-collapse.collapsing {
	width: 175px;
	display: block;
	position: absolute;
	top: 68px;
	right: 0;
	background-color: #fff;
	border-bottom-left-radius: 8px;
	text-align: left;
	padding: 8px;
	transition: none;
}

.navbar-collapse.collapse.show.darkmode,
.navbar-collapse.collapsing.darkmode {
	background-color: rgb(48,48,48)
}

#mobile-nav-filler {
	width: 56px;
}

@media screen and (max-width: 767px) {
	.btn-row {
		width: 400px;
		max-width: 80%;
	}
	#font-buttons {
		width: 50%;
	}
}