.snotes-navbar {
    background: #FFFFFF;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.0490829), 0px 15px 35px rgba(43, 64, 78, 0.095505);
    border-top: 3px #2D9CDB solid;
}

#snotes-navbar-main {
    max-width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.snotes-navbar-link {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #BDBDBD !important;
    transition: all ease-in .25s;
}

.snotes-navbar-link:hover {
    color: #2D9CDB !important;
}

.darkmode-switch-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-right: 40px;
}

#darkmode-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 12px;
    cursor: pointer;
}

@media screen and (min-width: 1200px) {
    #snotes-navbar-main {
        max-width: 1100px;
    }
}

.navbar-brand {
    margin-right: 0 !important;
}

.navbar-desktop-section {
    width: 220px;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 991px) {
    #snotes-navbar-desktop {
        display: none;
    }
    #snotes-navbar-mobile {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        z-index: 3;
    }
}

@media screen and (min-width: 992px) {
    #snotes-navbar-mobile {
        display: none;
    }
}

.navbar-toggler-icon {
    background-image: url('../images/hamburger.svg') !important;
}

#basic-navbar-nav {
    padding-left: 15px;
    z-index: 1000 !important;
}