#text-display-container {
    height: 240px;
    max-width: 730px;
    margin-left: auto;
    margin-right: auto;
    border-top: 8px solid #E0E0E0;
    border-bottom: 8px solid #E0E0E0;
    margin-top: 120px; 
    margin-bottom: 120px;
}

@media screen and (max-width: 991px) {
    #text-display-container {
        margin-bottom: 100px;
    }
}

#current-word-container {
    height: 192px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.text-display-tick {
    height: 16px;
    width: 8px;
    margin-left: auto;
    margin-right: auto;
    background-color: #E0E0E0;
}