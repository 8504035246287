.modal-container {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(255, 255, 255, 0.95);
	z-index: 10000;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px;
	transition: opacity 1s ease-in;
}

.modal-container.darkmode {
	background-color: rgba(0, 0, 0, 0.95);
}

.darkmode > .snotes-modal-body {
    background: #303030;
    border: none;
}

.snotes-modal-body {
    background: #FFFFFF;
    border: 1px solid #DFE0EB;
    box-sizing: border-box;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.0490829), 0px 15px 35px rgba(43, 64, 78, 0.095505);
    border-radius: 8px;
    width: 460px;
    max-width: 95%;
    height: 360px;
    top: 150px;
    position: absolute;
}



#tutorial-step-container {
    position: absolute;
    top: 256px;
}

.tutorial-arrow {
    position: absolute;
}

.snotes-modal-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid #DFE0EB;
}

.snotes-modal-header {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #465967;
}

.snotes-modal-main {
    padding: 27px 25px 35px;
}

.snotes-modal-copy {
    text-align: left;
    margin-bottom: 35px;
}

.modal-btn {
    width: 80px;
    height: 46px;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    padding-top: 10px;
    cursor: pointer;
}

.modal-next-btn {
    background-color: #2D9CDB;
    border-radius: 8px;
    color: #fff;
    transition: all ease-in-out .2s;
}

.modal-cancel-btn:hover {
    text-decoration: underline;
}

.modal-btn-container {
    width: 200px;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.modal-btn-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
}