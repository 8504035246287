.why-use-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 40px;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 1150px) {
    .why-use-container {
        flex-direction: column-reverse;
    }
    #why-use-header {
        text-align: center !important;
    }
}

@media screen and (max-width: 767px) {
    .why-use-row {
        max-width: 95% !important;
    }
}

#why-use-header {
    text-align: left;
    margin-bottom: 32px;
    margin-top: 32px;
}

.why-use-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-bottom: 20px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.why-use-check {
    color: #27AE60;
    margin-right: 12px;
    font-size: 20px;
}

.why-use-reason {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 160%;
    color: #465967;
    text-align: left;
}

#phone-preview {
    max-width: 100%;
    width: auto;
    height: auto;
}

.why-use-check-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}